import React from 'react';
import Newsletter from '../../../components/newsletter/Newsletter';
import TradeWithUs from '../../../components/trade-with-us/TradeWithUs';
import PageHeader from '../../../components/page-header/PageHeader';

const Regulations = () => {
  const regulationsInfo = [
    {
      title: `Options Tradehub: Commitment to Transparency and Security`,
      info: `At Options Tradehub, we prioritize the security, compliance, and peace of mind of our clients above all else. We understand that navigating the world of cryptocurrencies requires not only financial acumen but also a deep understanding of the regulatory landscape. As a responsible crypto trading company, we are dedicated to upholding the highest standards of regulatory compliance, ensuring that your trading experience is not only seamless but also in accordance with legal and ethical norms.`,
    },
    {
      title: `Our Regulatory Approach`,
      info: `Options Tradehub operates with a steadfast commitment to adhering to the regulations and laws governing the jurisdictions in which we operate. We recognize that the evolving nature of the cryptocurrency market necessitates a proactive stance on regulatory compliance. As such, we continually monitor changes in regulations and adapt our practices to ensure that we remain compliant at all times.`,
    },
    {
      title: `Customer Verification and AML Measures`,
      info: `In line with global anti-money laundering (AML) and know your customer (KYC) standards, Options Tradehub maintains rigorous customer verification processes. These processes are designed to prevent illegal activities such as money laundering, terrorist financing, and fraud. We require our clients to provide accurate identification and proof of address documents to establish their identities, creating a secure environment for all participants in the crypto market.`,
    },
    {
      title: `Data Protection and Privacy`,
      info: `Your data privacy is of utmost importance to us. We adhere to stringent data protection practices to safeguard your personal and financial information. Our data security measures are aligned with industry standards and regulations such as the General Data Protection Regulation (GDPR) where applicable, ensuring that your sensitive information is handled with the utmost care.`,
    },
    {
      title: `Compliance with Local Laws`,
      info: `We recognize that cryptocurrency regulations vary from one jurisdiction to another. Our commitment to compliance extends to the laws and regulations specific to each region in which we operate. This approach ensures that our clients can engage in trading activities without worrying about violating local laws.`,
    },
    {
      title: `Secure Transactions and Asset Protection`,
      info: `Options Tradehub employs cutting-edge security measures to protect your digital assets and ensure the integrity of transactions. We use secure encryption protocols, multi-factor authentication, and cold storage for the majority of funds to prevent unauthorized access and safeguard your investments.`,
    },
    {
      title: `Transparent Reporting`,
      info: `As part of our commitment to transparency, we provide clear and accurate reporting to our clients. You can access detailed transaction histories, account statements, and tax-related information easily through our platform, aiding in your compliance with tax regulations in your jurisdiction.`,
    },
    {
      title: `Continuous Monitoring and Adaptation`,
      info: `The crypto regulatory landscape is dynamic, and we understand the importance of staying informed. Our dedicated legal and compliance team actively monitors regulatory developments, ensuring that our policies and procedures evolve in response to changes in the industry and legal requirements.`,
    },
    {
      title: `Get in Touch`,
      info: `If you have any questions or concerns about our regulatory practices, please don't hesitate to contact our customer support team. We are here to address any queries you may have regarding regulatory compliance, security measures, or any other related matter. Options Tradehub is dedicated to creating a secure and compliant environment for your cryptocurrency trading journey. With our commitment to regulatory compliance, transparency, and data protection, you can trade with confidence, knowing that your interests are safeguarded.`,
    },

    {
      title: `Options Tradehub - Where Your Security Meets Our Compliance.`,
    },
  ];

  return (
    <main className='regulations-page'>
      <PageHeader pageTitle={`Regulation and Compliance `} />
      {/* REGULATIONS INFORMATION */}
      <div className='regulations-info'>
        {regulationsInfo.map((item, itemIndex) => {
          return (
            <div className='block' key={itemIndex}>
              <h3 className='title'>{item.title}</h3>
              <p>{item.info}</p>
            </div>
          );
        })}
      </div>
      <TradeWithUs />
      <Newsletter />
    </main>
  );
};

export default Regulations;
