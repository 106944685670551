import { DepositFundsForm } from '../../../components/forms/Forms';
import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../context/context';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import { PageSpinner } from '../../../components/spinner/Spinner';

const Deposit = () => {
  const [loading, setLoading] = useState(true);
  const [wallets, setWallets] = useState([]);
  const { baseURL } = useGlobalContext();
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${baseURL}/admin-wallets`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setWallets(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  console.log(wallets);

  return (
    <main className='content deposit-page'>
      <div className='title'>
        <h3>Deposit</h3>
        <h2>What would you like to deposit?</h2>
      </div>
      {loading ? <PageSpinner /> : <DepositFundsForm wallets={wallets} />}
    </main>
  );
};

export default Deposit;
