import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../context/context';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import toast from 'react-hot-toast';
import { PageSpinner, Spinner } from '../../../components/spinner/Spinner';
import { Modal, Select } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { HiPhoto } from 'react-icons/hi2';
import { useDisclosure } from '@mantine/hooks';
import { GoCheckCircleFill } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';

const KYC = () => {
  const { baseURL } = useGlobalContext();
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));
  const [files, setFiles] = useState([]);
  const [document, setDocument] = useState(``);
  const [loading, setLoading] = useState(`kyc-loading`);
  const [opened, { open, close }] = useDisclosure(false);
  const [kyc, setKyc] = useState({});

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    sedKYCRequest();
  };

  const sedKYCRequest = async () => {
    const formData = new FormData();
    formData.append(`document`, document);
    formData.append(`image`, files[0]);

    try {
      setLoading(`kyc-submission`);
      const { data } = await axios.post(`${baseURL}/kyc`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoading(``);
      console.log(data);
      open();
    } catch (error) {
      toast.error(error.response.data.msg);
      setLoading(``);
    }
  };

  const fetchKYC = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/kyc`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setKyc(data[0]);
      setLoading(``);
    } catch (error) {
      setLoading(``);
    }
  };

  useEffect(() => {
    fetchKYC();
    // eslint-disable-next-line
  }, []);

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <img
        key={index}
        src={imageUrl}
        onLoad={() => URL.revokeObjectURL(imageUrl)}
        alt=''
      />
    );
  });

  return (
    <main className='content kyc-page'>
      <div className='title'>
        <h3>KYC</h3>
        <h2>We need to verify your identity</h2>
      </div>

      {loading === `kyc-loading` ? (
        <PageSpinner />
      ) : !loading && kyc?.status === `approved` ? (
        <div className='kyc-approved'>
          <h1>Congratulations!</h1>
          <p>
            We're delighted to inform you that your Know Your Customer (KYC)
            document has been successfully reviewed and approved. This means
            your account is now fully verified, granting you unrestricted access
            to our automated trading platform.
          </p>
          <p>
            With your KYC document approved, you can now dive into all the
            features and benefits our platform offers, including real-time
            market data, automated trading strategies, and advanced analytics.
          </p>
          <p>
            Should you have any queries or need assistance, our support team is
            here to help. Feel free to reach out, and we'll be more than happy
            to assist you.
          </p>
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            {/* document */}
            <div className='select-crypto'>
              <Select
                label='Choose verification method'
                required
                name='type'
                value={document}
                onChange={(value) => {
                  if (!value) return;
                  setDocument(value);
                }}
                data={[
                  {
                    value: 'passport',
                    label: 'Passport',
                  },
                ]}
              />
            </div>

            <div className='upload-receipt'>
              <Dropzone
                accept={IMAGE_MIME_TYPE}
                onDrop={setFiles}
                maxFiles={1}
                aria-required
              >
                <div className='upload'>
                  <HiPhoto /> <p>Upload document</p>
                </div>
              </Dropzone>

              {files.length > 0 ? (
                <div className='preview'>{previews}</div>
              ) : null}
            </div>

            <div className='btn'>
              <button className='linear'>
                Submit for review
                {loading === `kyc-submission` ? <Spinner /> : null}
              </button>
            </div>
          </form>
          <Modal
            opened={opened}
            onClose={close}
            centered
            size='lg'
            className='deposit-successful-modal'
            overlayProps={{
              backgroundOpacity: 0.55,
            }}
          >
            <div className='icon'>
              <GoCheckCircleFill />
            </div>
            <h1>KYC Request Sent</h1>
            <p>
              Your request has been sent and it is now awaiting review and
              approval. Please be patient. Thank you!
            </p>

            <div className='btn'>
              <button
                className='linear'
                onClick={() => {
                  close();
                  navigate(0);
                }}
              >
                Close
              </button>
            </div>
          </Modal>
        </>
      )}
    </main>
  );
};

export default KYC;
