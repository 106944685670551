import React, { useEffect, useState } from 'react';
import PageHeader from '../../../components/page-header/PageHeader';
import Newsletter from '../../../components/newsletter/Newsletter';
import TradeWithUs from '../../../components/trade-with-us/TradeWithUs';
import { MdEmail } from 'react-icons/md';
import { RiWhatsappFill } from 'react-icons/ri';
import { ContactUsForm } from '../../../components/forms/Forms';
import axios from 'axios';
import { useGlobalContext } from '../../../context/context';

const ContactUs = () => {
  const [loading, setLoading] = useState(true);
  const { baseURL } = useGlobalContext();
  const [number, setNumber] = useState(``);

  useEffect(() => {
    const fetchWhatsapp = async () => {
      try {
        const { data } = await axios.get(`${baseURL}/whatsapp`);
        console.log(data);
        setNumber(data[0].number);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchWhatsapp();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <main className='contact-us-page'>
        <PageHeader
          pageTitle={`Contact Us`}
          briefInfo={`Find the best ways to get in contact with our customer support team available 24/7.`}
        />

        <div className='form-container'>
          <ContactUsForm />
          <div className='contact-info'>
            <div className='item'>
              <div className='img'>
                <RiWhatsappFill />
              </div>
              {loading ? (
                <h3>...</h3>
              ) : (
                <h3>
                  <a
                    href={`https://wa.me/${number}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {number}
                  </a>
                </h3>
              )}
            </div>
            <div className='item'>
              <div className='img'>
                <MdEmail />
              </div>
              <h3>
                <a href={`mailto:support@optionstradehub.com`}>
                  support@optionstradehub.com
                </a>
              </h3>
            </div>
          </div>
        </div>
        <TradeWithUs />
        <Newsletter />
      </main>
    </>
  );
};

export default ContactUs;
