import investmentBitcoin from '../../../assets/images/investment-bitcoin-mockup.png';
import investmentStocks from '../../../assets/images/investment-stocks.png';
import investmentCryptoIndices from '../../../assets/images/investment-indices.png';
import { FAQ } from '../../../components/faq/Faq';
import { CiLock } from 'react-icons/ci';
import {
  FcGenealogy,
  FcUnlock,
  FcLockPortrait,
  FcProcess,
  FcSettings,
} from 'react-icons/fc';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <>
      <main className='home-page'>
        <Header />
        <Investments />
        <ComplianceProtection />
        <HowItWorks />
        <FAQ />
      </main>
    </>
  );
};

const Header = () => {
  return (
    <header className='header'>
      <div className='info'>
        <h1>
          Revolutionizing Crypto Trading And Creating Your Gateway to Global
          Investment
        </h1>
        <p>
          Unleash the power of our comprehensive suite of trading tools and
          in-depth market analysis to seamlessly implement, scale, and refine
          your cryptocurrency trading strategies.
        </p>

        <div className='btns'>
          <Link to='/sign-in'>
            <button className='trans'>
              <CiLock /> Sign In
            </button>
          </Link>
          <Link to='/sign-up'>
            <button className='linear'>Create an Account</button>
          </Link>
        </div>
      </div>
    </header>
  );
};

const Investments = () => {
  const investmentTypes = [
    {
      title: `Stocks`,
      brief:
        'Invest in partial ownership of your preferred companies without the need to purchase an entire share.',
      image: investmentStocks,
    },
    {
      title: `Cryptocurrencies`,
      brief:
        'Buy, sell & swap the cryptocurrencies you want anytime, anywhere.',
      image: investmentBitcoin,
    },
    {
      title: `Crypto Indices`,
      brief: 'Auto-invest in the whole crypto market with a single click',
      image: investmentCryptoIndices,
    },
  ];

  return (
    <section className='investments'>
      <div className='title'>
        <h4>INVEST </h4>
        <h2>
          All investments. <br /> Available on Options Tradehub.
        </h2>
      </div>

      <div className='grid'>
        {investmentTypes.map((type, index) => {
          const { brief, image, title } = type;
          return (
            <div className='card' key={index}>
              <h2>{title}</h2>
              <p>{brief}</p>
              <div className='img'>
                <img src={image} alt='' />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

const HowItWorks = () => {
  const steps = [
    {
      title: `Register`,
      brief: `Sign up for an account on Options Tradehub by providing your email address, creating a password, and agreeing to the terms and conditions. Registration typically involves filling out a form with basic personal information.`,
      buttonLabel: `Get Started`,
      href: `/sign-up`,
    },
    {
      title: `Verify`,
      brief: `Complete the verification process by providing additional documentation to confirm your identity and comply with regulatory requirements. This step may involve uploading a government-issued ID, proof of address, and in some cases, undergoing a Know Your Customer (KYC) procedure.`,
    },
    {
      title: `Deposit Funds`,
      brief: `After your account is verified, deposit funds into your trading account using one of the supported payment methods. This usually includes bank transfers, credit/debit cards, or cryptocurrencies. Follow the platform's instructions to initiate the deposit process securely.`,
    },
    {
      title: `Automated Trading`,
      brief: `Set up your automated trading preferences by configuring parameters such as trading strategies, risk management rules, and allocation of funds. Choose from a range of predefined trading strategies or create your own customized approach. Once configured, the automated trading system will execute trades on your behalf based on the selected criteria.`,
    },
    {
      title: `Withdrawal`,
      brief: `When you're ready to withdraw funds from your trading account, navigate to the withdrawal section of the platform and initiate a withdrawal request. Specify the amount you wish to withdraw and select your preferred withdrawal method. Funds are typically transferred back to the original source of deposit or to a linked bank account. Ensure that you comply with any withdrawal limits and processing times specified by the platform.`,
    },
  ];

  return (
    <section className='how-it-works'>
      <div className='title'>
        <h4>HOW IT WORKS </h4>
        <h2>Get Started In Minutes</h2>
      </div>

      <div className='steps'>
        {steps.map((step, index) => {
          return (
            <div
              className={`item ${(index + 1) % 2 === 0 ? `reverse` : ``}`}
              key={index}
            >
              <div className='number'>
                <h2>0{index + 1}</h2>
                <div className='line'></div>
              </div>
              <div className='info'>
                <h3>{step.title}</h3>
                <p>{step.brief}</p>
                {step.buttonLabel && step.href ? (
                  <Link to={step.href}>
                    <button className='linear'>{step.buttonLabel}</button>
                  </Link>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

const ComplianceProtection = () => {
  const data = [
    {
      title: `Advanced Fund Protection`,
      icon: <FcGenealogy />,
      brief: `Leveraging industry-leading security practices, we safeguard your assets through offline cold wallet storage. Our comprehensive Triple Fund Protection further bolsters the security framework, ensuring unparalleled peace of mind for a trust-worthy and reliable experience.`,
    },
    {
      title: `Data Privacy & Security`,
      icon: <FcUnlock />,
      brief: `Built with data privacy and security at the core, our products and services prioritize your control over your information. We are transparent about the data we collect, and clearly explain how it is used and shared, empowering you to make informed choices.`,
    },
    {
      title: `End-to-End Encryption`,
      icon: <FcLockPortrait />,
      brief: `Your private and personal information remains protected throughout its journey, secured by end-to-end encryption both in transit and at rest. To further safeguard your data, strict authorization controls ensure only you have the keys to access it.`,
    },
    {
      title: `Live Insights`,
      icon: <FcProcess />,
      brief: `Options Tradehub prioritizes your security with Live Insights, continuously analyzing user behavior to identify potential risks. If suspicious activity is detected, enhanced authentication safeguards your withdrawals for an extra layer of protection.`,
    },
    {
      title: `Trustworthy by Design`,
      icon: <FcSettings />,
      brief: `Built with trust in mind, our system integrates a secure development life cycle, rigorous security testing, and ongoing bug bounty programs, solidifying its reliability and safeguarding your information.`,
    },
  ];

  return (
    <section className='compliance-protection'>
      <div className='title'>
        <h4>SECURITY</h4>
        <h2>Our Commitment to Compliance and Your Protection</h2>
      </div>

      <div className='grid'>
        {data.map((item, index) => {
          return (
            <div className='item' key={index}>
              <div className='title-icon'>
                <h3>{item.title}</h3>
                {item.icon}
              </div>
              <p>{item.brief}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Home;
