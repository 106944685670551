import companyLogo from '../../../assets/images/company-logo.svg';
import { Link } from 'react-router-dom';
import checkEmail from '../../../assets/images/verify-email.png';

const CheckEmail = () => {
  return (
    <main className='auth-page'>
      <Link to='/' className='logo'>
        <img src={companyLogo} alt='Options Tradehub' />
      </Link>
      <div className='verified'>
        <div className='check-email-img'>
          <img src={checkEmail} alt='' />
        </div>
        <p>
          We're excited to have you onboard! An email containing a verification
          link has just been sent to your inbox. Please check your email shortly
          and follow the instructions to verify your email address. Once
          verified, you'll gain access to our automated trading platform and all
          its features. If you encounter any issues or have any questions, feel
          free to reach out to our support team. We're here to help!
        </p>
      </div>
    </main>
  );
};

export default CheckEmail;
